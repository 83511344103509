import React from 'react';
import './Loading.css';
import { SpinnerCircular } from 'spinners-react';

const Loading = () => {
  return (
    <div className="loading-screen">
      <SpinnerCircular size="6%" secondaryColor="rgba(0,0,0,0.5)"/>
    </div>
  );
};

export default Loading;