import { Container, Row} from "react-bootstrap";
import 'animate.css';
import '../App.css'

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <div className="column-center">
            <h1>{`HoN Community Thailand`}</h1>
          </div>
        </Row>
      </Container>
    </section>
  )
}