export const Download = () => {

  return (
    <section className="download" id="download">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="download-bx wow zoomIn">
                        <h2>Download</h2>
                        <h3>HoN Installer (New version 4.10.9)</h3>
                        <>
                            <button onClick={(e) => {e.preventDefault(); window.open('https://name.kongor-th.online/HoNInstaller_4.10.9.zip',) || window.location.replace('https://name.kongor-th.online/HoNInstaller_4.10.9.zip');}}>Download</button>
                            <button onClick={(e) => {e.preventDefault(); window.open('/HoNInstaller_4.10.9.zip',) || window.location.replace('/HoNInstaller_4.10.9.zip');}}>Download 2</button>
                        </>
                        
                        <h4>Driver</h4>
                        <p>หากคุณพบข้อผิดพลาด โปรดติดตั้งไดร์เวอร์และโปรแกรมเสริมนี้</p>
                        <>
                            <button onClick={(e) => {e.preventDefault(); window.open('https://www.microsoft.com/en-us/download/details.aspx?id=35', "_blank") || window.location.replace('https://www.microsoft.com/en-us/download/details.aspx?id=35');}}>Direct X</button>

                            <button onClick={(e) => {e.preventDefault(); window.open('https://aka.ms/vs/17/release/vc_redist.x64.exe', "_blank") || window.location.replace('https://aka.ms/vs/17/release/vc_redist.x64.exe');}}>VC Redist</button>

                            <button onClick={(e) => {e.preventDefault(); window.open('https://www.winrar.in.th/download-winrar-for-windows', "_blank") || window.location.replace('https://www.winrar.in.th/download-winrar-for-windows');}}>Winrar</button>
                        </>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
