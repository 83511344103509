import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Footer } from "./components/Footer";
import Snowfall from 'react-snowfall'
import { Download } from './components/Download';
import { useEffect, useState } from 'react';
import Loading from './components/Loading'

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {loading ? (
          <Loading />
        ) : (
          <div className="App">
            <Snowfall
              color="#fff"
              snowflakeCount={0}
              speed={[0.5, 1.5]}
              wind={[-1.5, 1.5]}/>
            <NavBar />
            <Banner />
            <Download />
            <Footer />
          </div>
        )
      }
    </>
  );
}

export default App;
